import React from "react";

import { FaGithubAlt } from "react-icons/fa";

import { AiFillLinkedin, AiTwotoneMail } from "react-icons/ai";
// AiFillPrinter

function contacts(props) {
  return (
    <ul className="contact_container">
      <li className="github">
        <a
          href="https://rebrand.ly/dev2github"
          target="_blank"
          rel=" noreferrer noopener"
        >
          <FaGithubAlt /> GitHub
        </a>
      </li>

      <li className="linkedin">
        <a
          href="https://rebrand.ly/dev2linkedin"
          target="_blank"
          rel=" noreferrer noopener"
        >
          <AiFillLinkedin /> Linkdin
        </a>
      </li>
      <li className="email">
        <a
          href="mailto:jacky.ong@outlook.com"
          target="_blank"
          alt="mailto:jacky.ong@outlook.com"
          rel="noreferrer noopener"
        >
          <AiTwotoneMail /> Email
        </a>
      </li>
    </ul>
  );
}

export default contacts;
