import { Link } from "react-scroll";

import "../css/navi.scss";

export function navigation() {
  const title = [
    { "🔝": "Home" },
    // { "🎓": "Education" },
    // { "📐": "Skills" },
    // { "📃": "Resume" },
    { "🦝": "Projects" },
    { "💌": "Contact" },
  ];

  const mapNavigations = title.map((el, i) => {
    let icon = Object.keys(el)[0];
    let text = Object.values(el)[0];
    let lowerText = Object.values(el)[0].toLowerCase();

    return (
        <li key={`${i}`} className="nav-item">
          <Link
            spy={true}
            smooth={true}
            offset={-0}
            duration={500}
            to={`${lowerText}`}
          >
            {icon + " " + text}
          </Link>
        </li>
    );
  }, 0);

  return (
      <ul>{mapNavigations}</ul>
  );
}

export default navigation;
