import React, { Component } from "react";

import "./App.css";
import "../src/css/hero.scss"

import resume from "./text/resume";
import "./media.css";

import {
  educationSection,
  workSection,
  skillsSection,
} from "./components/sections";

import navigation from "./components/navigation";

import renderProjects from "./components/projects";

import contacts from "./components/contact";
import formOutput from "./components/form";

class App extends Component {
  render() {
    const dev = resume.basics; //object
    // const skills = resume.skills; //array
    const work = resume.work; //array
    const edu = resume.education; //array

    return (
      <div className="App">
        <div className="container">
          <nav className = "navigation">{navigation()} </nav>
                  
          <section className="home">
            <div className="hero-bg">
              <div className="hero-bg-filter"></div>
              <p>Aspiring Full Stack Web Developer</p>
              <h1> {dev.name}</h1>
              <p>He, Him & His — Technophile — New York — Queens </p>
            </div>
          </section>
            <section className="projects">
            {renderProjects(`Projects`)}
          </section>
          <section className="contact">
            <h1>💌 Contact</h1>
            <> {contacts()} </>
            <> {formOutput()} </>
          </section>
          <footer className="footer">
            <span> © 2021 - Feb | Jacky Ong | Made with 🤷‍♂️ </span>
          </footer>
        </div>
      </div>
    );
  }
}

export default App;

