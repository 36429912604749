import React from "react";

export function formOutput(params) {
  return (
    <>
      <form
        className="form"
        action="https://formspree.io/f/mknqjwla"
        method="POST"
      >
        <label htmlFor="name"> 📛 Name: </label>
        <input type="text" id="name" name="name" autoComplete="on" />
        <label htmlFor="email" required>
          📧 Email:
        </label>
        <input type="email" name="_replyto" autoComplete="on" />
        <div className="textarea">
          <label htmlFor="text"> 🔡 Message: </label>
          <textarea id="text" name="text" required />
        </div>

        <div className="buttons">
          <button type="reset"> 🧨 Reset </button>
          <button type="submit" value="Send">
            🏹 Send
          </button>
        </div>
      </form>
    </>
  );
}

export default formOutput;
