import {
  FaWordpressSimple,
  FaMicrosoft,
  FaDev,
  FaSass,
  FaJsSquare,
  FaCss3Alt,
  FaReact,
  FaNode,
  FaRegSun,
} from "react-icons/fa";
//  FaFileExcel,

import {
  AiFillApi,
  AiFillGithub,
  AiOutlineConsoleSql,
  AiFillDatabase,
} from "react-icons/ai";
import { DiPhotoshop, DiPostgresql, DiHtml5, DiGitMerge } from "react-icons/di";

import { MdWeb } from "react-icons/md";

// Sections Components

//education history section
export function educationSection(header, array) {
  const x = array.map((el) => {
    console.log(el);
    return (
      <div className="card">
        <h2>{el.institution} </h2>
        <p>{el.studyType}</p>
        <p>{el.area}</p>
        <i>{el.summary}</i>
      </div>
    );
  });

  return (
    <>
      <h1>
        <span role="img" aria-label="graduation cap">
          🎓
        </span>
        {header}
      </h1>
      <div className="cards">{x}</div>
    </>
  );
}

//work history section
export function workSection(header, array) {
  const x = array.map((el, i) => {
    const highlightsArray = el.highlights;
    // const lists = highlightsArray.map((el) => {
    //   return <li>{el}</li>;
    // });
    const title =
      el.company === "Queens Botanical Garden" && i !== 0 ? null : el.company;

    return (
      <div className="card">
        <h2>{title == null ? `` : `@ ${title}`} </h2>
        <h3>
          {" "}
          {el.position} {el.startDate} – {el.endDate}{" "}
        </h3>
        {/* <p>{el.summary}</p> */}
        {/* <ul>{lists}</ul> */}
      </div>
    );
  });

  return (
    <div className="resume">
      <h1>
        <span role="img" aria-label="briefcase">
          💼
        </span>
        {header}
      </h1>
      <div className="cards">{x}</div>
      <div className="resume_link">
        <a
          href="https://rebrand.ly/dev2googleresume"
          target="_blank"
          rel=" noreferrer noopener"
        >
          Resume Download 📤
        </a>
      </div>
    </div>
  );
}

//skills
export function skillsSection(header, array) {
  return (
    <>
      <h1 className="skills_title">
        <span role="img" aria-label="wrench">
          🛠️
        </span>
        {header}
      </h1>

      <div className="skill cards">
        <fieldset>
          <legend>
            <MdWeb /> Frontend
          </legend>
          <ul className="skill_sets">
            <li className="HTML">
              <DiHtml5 /> <p>HTML5</p>
            </li>
            <li className="CSS">
              <FaCss3Alt /> <p>CSS3</p>
            </li>
            <li className="JS">
              <FaJsSquare />
              <p>JavaScript </p>
            </li>
            <li className="React">
              <FaReact /> <p>React</p>
            </li>
            <li className="Sass">
              <FaSass /> <p>Sass</p>
            </li>
          </ul>
        </fieldset>

        <fieldset>
          <legend>
            <AiFillDatabase /> Backend{" "}
          </legend>
          <ul className="skill_sets">
            <li className="Node">
              <FaNode /> <p>NodeJS</p>
            </li>
            <li className="Express">
              <FaRegSun /> <p>ExpressJS </p>
            </li>
            <li className="REST">
              <AiFillApi /> <p>Rest API</p>
            </li>
            <li className="PSQL">
              <DiPostgresql /> <p>PostgreSQL</p>
            </li>
            <li className="SQL">
              <AiOutlineConsoleSql /> <p>SQL</p>
            </li>
          </ul>
        </fieldset>

        <fieldset>
          <legend>
            <FaDev /> Others
          </legend>

          <ul className="skill_sets">
            <li className="WordPress">
              <FaWordpressSimple /> <p>WordPress</p>
            </li>
            <li className="PS">
              <DiPhotoshop /> <p>Photoshop </p>
            </li>
            <li className="GitHub">
              <AiFillGithub /> <p> GitHub </p>
            </li>
            <li className="Git">
              <DiGitMerge /> <p> Git </p>
            </li>

            <li className="MS">
              <FaMicrosoft /> <p> Office 365 </p>
            </li>
          </ul>
        </fieldset>
      </div>
    </>
  );
}

//export
let exportFunction = { educationSection, workSection, skillsSection };

export default exportFunction;
