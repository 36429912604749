const resume = {
  basics: {
    name: "Jacky Ong",
    label: "",
    picture: "",
    email: "jacky.ong@outlook.com",
    phone: "(347)815-5101",
    website: "www.jackyo.dev",
    summary: "",
    location: {
      address: "",
      postalCode: "11355",
      city: "Queens",
      countryCode: "US",
      region: "New York",
    },
    profiles: [
      {
        network: "Github",
        username: "jackyodev",
        url: "www.github.com/jackyodev",
      },
      {
        network: "Linkdin",
        username: "jacky-ong",
        url: "www.linkedin.com/in/jacky-ong",
      },
    ],
  },
  work: [
    {
      company: "Queens Botanical Garden",
      position: "Visitor Services Assistant Manager & Applications Analyst",
      website: "https://www.queensbotanical.org",
      startDate: "2013",
      endDate: "2021",
      summary: "Description...",
      highlights: [
        "Aggregate and examine visitation data and provide findings to the Department of Cultural Affairs. Use metrics provided to interpret areas of potential growth for the organization.",
        "Provide onsite technical support and streamline operation with technology already offered.",
        "Handle and maintain company’s intranet.",
      ],
    },
    // {
    //   company: "Queens Botanical Garden",
    //   position: "Assistant Manager of Visitor Services",
    //   website: "queensbotanical.org",
    //   startDate: "2017",
    //   endDate: "2020",
    //   summary: "Description...",
    //   highlights: [
    //     "Execute managerial support in admission, parking, public program, gift shop, facility rental, and membership area. Provide de-escalation support for front-line staff.",
    //     "Responsible for the point-of-sale system, credit card terminals, and invoicing system. Write technical guides, set up users, and configure GUI for use. Ensure basic PCI DSS requirements are met.",
    //   ],
    // },
    // {
    //   company: "Queens Botanical Garden",
    //   position: "Visitor Services & Public Program Coordinator ",
    //   website: "www.queensbotanical.org",
    //   startDate: "2016",
    //   endDate: "2017",
    //   summary: "Description...",
    //   highlights: [
    //     "Manage opening/closeout, staffing and break schedules logistics, budget planning, and volunteer daily tasks and performance review.",
    //     "Pitch, plan, and coordinate public programs with team members.",
    //   ],
    // },
    // {
    //   company: "Queens Botanical Garden",
    //   position: "Visitor Services Associate",
    //   website: "www.queensbotanical.org",
    //   startDate: "2014",
    //   endDate: "2016",
    //   summary: "Description...",
    //   highlights: [
    //     "Developed a kiosk app for visitors to use in the Visitor Centre, and other applications to assist with organization administration tasks.",
    //   ],
    // },
    // {
    //   company: "Queens Botanical Garden",
    //   position: "Visitor Services Assistant",
    //   website: "www.queensbotanical.org",
    //   startDate: "2013",
    //   endDate: "2014",
    //   summary: "Description...",
    //   highlights: [
    //     "Trained admission team members and volunteers on customer support.",
    //     "Resolved visitors' inquire and provide support for operation staff.",
    //     "Assisted HR, Finance, and Membership support during staff leave.",
    //     "Managed commercial bus parking space rentals.",
    //   ],
    // },
    {
      company: "Helm Management / NYREX",
      position: "Administration  Coordinator",
      website: "",
      startDate: "2011",
      endDate: "2012",
      summary: "Description...",
      highlights: [
        "Spearheaded website redesign to WordPress.",
        "Resolved and tracked building violations.",
        "Managed tenants’ applications, background checks, and rental contracts.",
      ],
    },
    {
      company: "Focus Medical Clinic ",
      position: "Tech Support",
      website: "",
      startDate: "2013",
      endDate: "2014",
      summary: "Description...",
      highlights: [
        "Setup and upgrade server and workstations for the transition to Electronic Medical Record system",
        "Provided onsite or remote tech support for workstations, printers, and network issues",
        "Provided routine data backup, system imaging, virus scans, and driver updates",
      ],
    },
  ],
  volunteer: [
    {
      organization: "Organization",
      position: "Volunteer",
      website: "http://organization.com/",
      startDate: "2012-01-01",
      endDate: "2013-01-01",
      summary: "Description...",
      highlights: ["Awarded 'Volunteer of the Month'"],
    },
  ],
  education: [
    {
      institution: "👨‍💻 Pursuit",
      area: "Full Stack Web Development",
      studyType: "Fellowship",
      startDate: "2018",
      endDate: "2019",
      gpa: "",
      courses: [
        "HTML",
        "CSS",
        "JavaScript,",
        "PostgreSQL",
        "ExpressJS",
        "React",
        "NodeJS",
        "RESTful API",
      ],
      summary: `Used PostgreSQL, Express, React, and Node.js to build full-stack web applications with CRUD operations.`,
    },
    {
      institution: "👨🏻‍🎓 CUNY Baruch College",
      area: "Psychology and Asian American Studies",
      studyType: "Bachelor of Arts",
      startDate: "2008",
      endDate: "2011",
      gpa: "3.8",
      courses: [""],
      summary:
        "Part of the SEEK Alumni & Chi Alpha Epsilon Chapter at Baruch & participated in Study Abroad in Japan",
    },
  ],
  awards: [
    {
      title: "",
      date: "",
      awarder: "",
      summary: "",
    },
  ],
  publications: [
    {
      name: "",
      publisher: "",
      releaseDate: "",
      website: "",
      summary: "",
    },
  ],
  skills: [
    {
      name: "Front End",
      level: "Proficient",
      keywords: ["HTML", "CSS/SASS", "Javascript", "React/React Native"],
    },
    {
      name: "Back End",
      level: "Proficient",
      keywords: ["SQL", "PostgreSQL", "ExpressJS", "Rest API"],
    },
    {
      name: "Others",
      level: "Proficient",
      keywords: ["Adobe Photoshop", "Office 365"],
    },
  ],
  languages: [
    {
      language: "English",
      fluency: "Native Speaker",
    },
    {
      language: "Mandarin-Chinese",
      fluency: "Casual Conversation",
    },
  ],
  interests: [
    {
      name: "",
      keywords: [""],
    },
  ],
  references: [
    {
      name: "",
      reference: "",
    },
  ],
};

export default resume;
