import React from "react";

import NS from "../assets/NS/sample.png";
import FYB from "../assets/FYB/sample.png";
import CS from "../assets/CSLogger/sample.png";
import QBG from "../assets/QBG/sample.png";
import Weather from "../assets/Weather/sample.png";

import { FaGithubAlt, FaReact } from "react-icons/fa";

import "../css/project.scss"


const proj = [
  {
    title: "Weather App",
    initial: "Weather",
    stack: "React, HTML, CSS",
    img: Weather,
    imgAlt: "",
    text:
      "The Weather App is a Single Page Application created from create-react-app and designed to be a full-screen weather app that shows you the current weather and the seven-day forecast for a particular area.",
    github: "https://github.com/jackyodev/weather/",
    demo: "https://weather-jackyo-app.netlify.app/",
  },
  {
    title: "Garden Tour App",
    initial: "Garden",
    stack: "React, HTML, CSS, Heroku, PostgresSQL",
    img: Weather,
    imgAlt: "",
    text:
      "Tour is a virtual tour app that provides knowledge to visitors about the history of the Garden,  information about the gardens and plants at the Queens Botanical Garden.",
    github: "https://github.com/jackyodev/tour",
    demo: "https://qbgapp2.herokuapp.com/",
  },
  {
    title: "Next Step",
    initial: "NS",
    stack: "React, Redux, HTML, CSS, ExpressJS, NodeJS, PostgresSQL",
    img: NS,
    imgAlt: "",
    text:
      "NextStep is an app designed to assist users in their behavior interviews. The app provides a community for users to answer interview questions and provide feedback for other users",
    github: "https://github.com/jackyodev/Next-Step",
    demo: "https://nextstep-jackyo.herokuapp.com",
  },
  {
    title: "TrackTile",
    initial: "TTile",
    stack: "React, PostgreSQL, ExpressJS, NodeJS, HTML, CSS",
    img: CS,
    imgAlt: "",
    text:
      "TrackTile is a quick & straightforward application to replace your aging pen and paper volunteer log. Monitor the time-ins and outs for your volunteers, log mandate hours and enable team leaders to see reports of volunteers log by date range.",
    github: "https://github.com/jackyodev/CommunityServicesTracker",
    demo: "https://ttile.herokuapp.com/",
  },
  // {
  //   title: "Garden Info Kiosk App",
  //   initial: "QBG",
  //   stack: "MS PowerApps, MS Excel, HTML",
  //   img: QBG,
  //   imgAlt: "",
  //   text:
  //     "A touchscreen Windows App that provides events, plants, location, and other educational information to visitors.",
  //   github: "",
  //   demo: "https://sites.google.com/view/qbgkioskapp/home",
  // },
  // {
  //   title: "Find Your Buddy",
  //   initial: "FYB",
  //   stack: "React, NodeJS, RescuePetsAPI, HTML, CSS",
  //   img: FYB,
  //   imgAlt: "",
  //   text:
  //     "Find Your Buddy is a hackathon web app built to help users expedite their adoption process. The app use data for pet adoption using the RescueGroups.org API.",
  //   github: "https://github.com/JJGITTY2018/find-your-buddy",
  //   demo: "https://findyurbuddy-jackyo.netlify.app/",
  // },
];

function showHideLink(type, url) {
  if (type === "code" && url !== "") {
    return (
      <a className="code" href={url} target="_blank" rel=" noreferrer noopener">
        <FaGithubAlt /> Code
        <div className="slide"> </div>
      </a>
    );
  } else if (type === "demo" && url !== "") {
    return (
      <a className="demo" href={url} target="_blank" rel=" noreferrer noopener">
        <div className="slide"> </div>
        <FaReact /> Demo
      </a>
    );
  } else return <div></div>;
}

function stackStringArray(string) {
  let arraySplit = string.split(", ");

  const map = arraySplit.map((el) => {
    return (
      <div className={`${el}`} key={el}>
        {el}
      </div>
    );
  });

  return map;
}

function project(project, i) {
  let { title, initial, text, stack, img, github, demo } = project;
  return (
    <div className={`project ${initial}`} key={i.toString()}>
      <div className="info">
        <div>
          <img
            className="device image"
            alt={`projects ${initial}capability devices`}
            src={img}
          />
        </div>

        <div>
          <h2 className="title">{title}</h2>

          <span className="stack"> {stackStringArray(stack)} </span>

          <p className="text"> {text} </p>

          <div className="links">
            {showHideLink("demo", demo)}
            {showHideLink("code", github)}
          </div>
        </div>
      </div>
    </div>
  );
}

function renderProjects(text) {
  let projectArray = proj.map((el, i) => {
    return project(el, i);
  });

  return (
    <>
      <h1>🦝{text}</h1>
      <div className="projectContainer">{projectArray}</div>
    </>
  );
}

export default renderProjects;
